import NotifyMixin from "@/mixins/NotifyMixin";
import DateRangeFilterMixin from '@/components/auth/DateRangeFilterMixin';
import {get} from "lodash-es";

export default {
  name: "UsersFilter",
  mixins: [NotifyMixin, DateRangeFilterMixin],
  computed: {
    customerId: function () {
      return this.$route.params.customerId;
    }
  },
  data: function () {
    return {
      roles: [],
      metaroles: [],
      communities: [],
      apps: [],
      filter: {
        roleIds: [],
        appIds: [],
        communityIds: [],
        createdAt: {
          startDate: null,
          endDate: null,
        },
        status: null,
      },
    };
  },
  created() {
    this.$authDataProvider.getList('customerRoles', {customerId: this.customerId, size: 999})
      .then(({content: roles}) => {
        this.roles = roles.map(({alias, roleId: key, appName}) => ({
          value: `${appName}: ${alias}`,
          key
        }));
      })
      .catch((err) => this.notifyError(err.message));

    this.$authDataProvider.getList('customerApps', {customerId: this.customerId, size: 999})
      .then(apps => {
        this.apps = apps.filter(app => !app.isCustomerUserAgnostic)
          .map(({name: value, id: key}) => ({
            key,
            value
          }));
      })
      .catch((err) => this.notifyError(err.message));

    this.$authDataProvider.getList('customerCommunities', {customerId: this.customerId, size: 999, nonDeleted: true})
      .then(({content: communities}) => {
        this.communities = communities.map(({name: value, id: key}) => ({
          key,
          value
        }));
      })
      .catch((err) => this.notifyError(err.message));

    Promise.all([
      this.$authDataProvider.getList('bundles', { size: 999, nonDeleted: true }),
      this.$authDataProvider.getOne('customers', { id: this.customerId })
    ])
      .then(([bundles, {licenses = []} = {}]) => {
        const licensedBundleIds = licenses.map(({bundleId}) => bundleId);
        this.metaroles = bundles.content
            .filter(({ id }) => licensedBundleIds.includes(id))
            .reduce((acc, { name, metaroles = [] }) => ([
                ...acc,
                ...metaroles.map(item => ({ key: item.id, value: `${name}: ${item.name}`})),
              ]), []);
      })
      .catch((err) => this.notifyError(err.message));

  },
  methods: {
    mapFilterToQuery(values) {
      const { from: createdAtFrom, to: createdAtTo } = this.getFilterDateRange(values.createdAt);

      return {
        roleIds: get(values, 'roleIds', []),
        metaRoleIds: get(values, 'metaRoleIds', []),
        appIds: get(values, 'appIds', []),
        communityIds: get(values, 'communityIds', []),
        status: get(values, 'status', null),
        createdAtFrom,
        createdAtTo,
      };
    },
  }
}
